<template>
    <b-card
            no-body
            class="p-1"
    >
        <b-overlay
                :show="state.loading"
                rounded="sm"
        >
            <search-and-filter
                    :is-active.sync="isActive"
                    :options="columns"
                    @filter="getData(1, perPage)"
                    @reset="getData(1, perPage)"
            />

            <!--            accumulate-->
            <b-modal
                    ref="new-wallet-modal"
                    cancel-variant="outline-secondary"
                    ok-title="تجمیع ارزها "
                    cancel-title="لغو"
                    centered
                    title="تجمیع"
                    @ok.prevent="accumulate"
                    v-model="accumulateModal"
            >
                <b-form>
                    <b-overlay
                            :show="loading"
                            rounded="sm"
                    >
                        <b-form-group label="شناسه درخواست">

                            <b-input-group class="mb-1">
                                <b-form-input dir="ltr" class="text-right" readonly
                                              v-model="accumulateWallet.label" trim
                                              placeholder="شناسه درخواست"/>
                            </b-input-group>

                        </b-form-group>

                        <b-form-group label="حداقل موجودی حساب">

                            <b-input-group class="mb-1">
                                <b-form-input dir="ltr" class="text-right" readonly
                                              v-model="accumulateWallet.minBalance" trim
                                              placeholder="حداقل موجودی حساب"/>
                            </b-input-group>

                        </b-form-group>
                        <b-form-group label="حداکثر کارمزد مصرفی" v-if="accumulateWallet.relatedCoin==='TETHER'">

                            <b-input-group class="mb-1">
                                <b-form-input dir="ltr" class="text-right" readonly
                                              v-model="accumulateWallet.maxBurn" trim
                                              placeholder="حداکثر کارمزد مصرفی"/>
                            </b-input-group>

                        </b-form-group>
                        <b-form-group label="رمز کیف پول">

                            <b-input-group class="mb-1" v-tooltip="'حداقل 10 کاراکتر'">
                                <b-form-input :state="accumulateWallet.password.length>9" dir="ltr" class="text-left"
                                              v-model="accumulateWallet.password" trim placeholder="رمز کیف پول"
                                              :type="passwordType"/>
                                <b-input-group-append is-text>
                                    <feather-icon
                                            :icon="passwordType==='password'?'EyeIcon':'EyeOffIcon'"
                                            class="cursor-pointer"
                                            @click="passwordType = passwordType==='password'? 'text':'password'"
                                    />
                                </b-input-group-append>
                            </b-input-group>

                        </b-form-group>
                        <!--<b-form-group label="کلید امنیتی 12 کلمه ای" v-else>

                            <b-input-group class="mb-1">
                                <b-form-textarea
                                        :state="mnemonicRegex(accumulateWallet.mnemonic)"
                                        dir="ltr" class="text-left"
                                        v-model="accumulateWallet.mnemonic" trim placeholder="12 key phrase"
                                />
                            </b-input-group>

                        </b-form-group>-->
                    </b-overlay>
                </b-form>
            </b-modal>

            <div class="m-1 d-flex justify-content-between"
                 dir="ltr"
            >
                <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" @click="isActive=true">
                    فیلتر
                    <feather-icon icon="FilterIcon"/>
                </b-button>
            </div>

            <div class="relative-over-x">
                <b-table
                        ref="refUserListTable"
                        :items="items"
                        :small="true"
                        responsive
                        :fields="columns"
                        primary-key="id"
                        show-empty
                        empty-text="اطلاعاتی یافت نشد"
                        @sort-changed="sort($event)"
                        no-local-sort
                        style="white-space: nowrap; min-height : 235px"
                >
                    <template #cell(createdAtDateTime)="data">
                <span dir="ltr">
                    {{$G2J(data.item.createdAtDateTime)}}
                </span>
                    </template>

                    <template #cell(relatedCoin)="data">
                        <b-avatar
                                size="25"
                                class="mr-50"
                                :src="require(`@/assets/images/Coins/${data.item.relatedCoin}.png`)"
                                variant="light-info"
                        />
                        {{$coinLabel[data.item.relatedCoin]}}
                    </template>

                    <template #cell(gatherRequestStatus)="data">
                        <b-badge
                                pill
                                :variant="'light-'+statusVariant(data.item.gatherRequestStatus)"
                                class="text-capitalize"
                        >
                            {{statusLabel(data.item.gatherRequestStatus)}}
                        </b-badge>
                    </template>

                    <template #cell(action)="{item}">
                        <template v-if="item.gatherRequestStatus === 'BURN_DONE'">
                            <b-button @click="coinAccumulation(item)">
                                تکمیل درخواست
                            </b-button>
                        </template>
                    </template>

                </b-table>
            </div>

            <!-- pagination -->
            <div
                    class="demo-spacing-0 d-flex justify-content-between m-1"
                    dir="rtl"
            >
                <b-pagination
                        v-model="currentPage"
                        :total-rows="rows"
                        :per-page="perPage"
                        first-number
                        last-number
                        prev-class="prev-item"
                        next-class="next-item"
                        align="left"
                        @input="getData(currentPage,perPage)"
                >
                    <template #prev-text>
                        <feather-icon
                                icon="ChevronLeftIcon"
                                size="18"
                        />
                    </template>
                    <template #next-text>
                        <feather-icon
                                icon="ChevronRightIcon"
                                size="18"
                        />
                    </template>
                </b-pagination>

                <div>
                    <label for="perpage">تعداد در صفحه</label>
                    <v-select
                            id="perpage"
                            v-model="perPage"
                            dir="rtl"
                            :options="perPageOptions"
                            :clearable="false"
                            class="per-page-selector d-inline-block mx-50"
                            style="min-width: 85px"
                            @input="getData(1,perPage)"
                    />
                </div>
            </div>
        </b-overlay>
    </b-card>
</template>

<script>
    import {
        BPagination,
        BCard,
        BButton,
        BTable,
        // BDropdown,
        // BDropdownItem,
        BBadge,
        BAvatar,
        BForm,
        BFormInput,
        BInputGroup,
        BInputGroupAppend,
        BFormGroup,
        BOverlay,
        // BListGroup,
        // BListGroupItem,
    } from 'bootstrap-vue'
    import SearchAndFilter from "@/layouts/components/SearchAndFilter";
    import vSelect from 'vue-select';
    // import NotAllowed from "@/layouts/components/NotAllowed";

    export default {
        name: 'GatherRequests',
        components: {
            // NotAllowed,
            SearchAndFilter,
            BPagination,
            BCard,
            BButton,
            BTable,
            // BDropdown,
            // BDropdownItem,
            BBadge,
            BAvatar,
            vSelect,
            BForm,
            BFormInput,
            BInputGroup,
            BInputGroupAppend,
            BFormGroup,
            BOverlay,
            // BListGroup,
            // BListGroupItem,

        },
        data: () => ({
            test: 0,
            pageLength: 3,
            dir: false,
            searchTerm: '',
            currentPage: 1,
            perPage: 10,
            rows: 20,
            sortBy: '',
            isSortDirDesc: '',
            userData: [],
            label: '',
            transactionHash: '',
            tomanModal: false,
            vandarModal: false,
            accumulateModal:false,
            cryptoModal: false,
            isActive: false,
            perPageOptions: [5, 10, 20, 50, 100],
            items: [],
            passwordType : 'password',
            vandar: {
                mobile: '',
                password: ''
            },
            accumulateWallet: {
                password: '',
                minBalance: '',
                maxBurn: '',
                label: ''
            },
            columns: [
                /*{
                    label: 'رمز ارز',
                    key: 'relatedCoin',
                    sortable: true,
                    searchType: 'select',
                    selectOptions: [
                        {label: 'اتریوم', value: 'ETHEREUM'},
                        {label: 'سلو', value: 'CELO'},
                        {label: 'بایننس کوین', value: 'BINANCE_COIN_BSC'},
                        {label: 'ماتیک', value: 'MATIC'},
                        {label: 'ترون', value: 'TRON'},
                        {label: 'تتر', value: 'TETHER'},
                    ]
                },*/
                {
                    label: 'نام کیف پول',
                    key: 'walletName',
                    // sortable: true,
                    searchType: 'text'
                },
                {
                    label: 'شناسه درخواست',
                    key: 'label',
                    // sortable: true,
                    searchType: 'text'
                },
                {
                    label: 'حداقل موجودی',
                    key: 'minBalance',
                    sortable: true,
                    searchType: 'number'
                },
                {
                    label: 'حداکثر کارمزد',
                    key: 'maxBurn',
                    sortable: true,
                    searchType: 'number'
                },
                {
                    label: 'تاریخ درخواست',
                    key: 'createdAtDateTime',
                    sortable: true,
                    searchType: 'date'
                },
                {
                    label: 'وضعیت درخواست',
                    key: 'gatherRequestStatus',
                    sortable: true,
                    searchType: 'select',
                    selectOptions: [
                        {label: 'جمع آوری انجام شده', value: 'GATHER_DONE'},
                        {label: 'در حال جمع آوری', value: 'GATHER_PENDING'},
                        {label: 'بارگذاری انجام شده', value: 'BURN_DONE'},
                        {label: 'در حال بارگذاری', value: 'BURN_PENDING'},
                        {label: 'درحال انجام', value: 'PENDING'},
                    ]
                },
                {
                    label: 'عملیات',
                    key: 'action'
                },
            ],
            walletName: '',
            walletUnit: '',
            request: '',
            selectedWallet: '',
            walletsList: '',
            password: '',
            loading: false,
            wallets: [
                {name: 1, totalBalance: 52},
                {name: 0, totalBalance: 85},
                {name: 100, totalBalance: 22},
            ],
        }),
        computed: {
            statusVariant() {
                const a = {
                    PENDING: 'warning',
                    BURN_PENDING: 'warning',
                    BURN_DONE: 'secondary',
                    GATHER_PENDING: 'warning',
                    GATHER_DONE: 'success',
                }
                return e => a[e];
            },
            statusLabel() {
                const a = {
                    PENDING: 'درحال انجام',
                    BURN_PENDING: 'در حال بارگذاری',
                    BURN_DONE: 'بارگذاری انجام شده',
                    GATHER_PENDING: 'در حال جمع آوری',
                    GATHER_DONE: 'جمع آوری انجام شده',
                }
                return e => a[e];
            },
            allowAccumulate() {
                return this.accumulateWallet.password.length > 9
            },
        },
        methods: {

            async coinAccumulation(e) {
                this.accumulateWallet = {
                    password: '',
                }
                this.accumulateWallet = {...this.accumulateWallet, ...e}
                this.accumulateModal = true
            },

            async accumulate() {
                if (this.allowAccumulate && !this.loading) {
                    this.loading = true
                    const [res,] = await this.$http.post('/wallets/gather-currencies', this.accumulateWallet)
                    this.loading = false
                    if (res) {
                        this.$swal({
                            icon: 'success',
                            title: 'درخواست تجمیع به شماره ' + res?.data?.baseDTO?.label + ' در حال انجام است.',
                            confirmButtonText: 'تایید',
                            // text: '',
                            customClass: {
                                confirmButton: 'btn btn-success',
                            },
                        }).then(() => {
                            this.newWalletModal = false
                        })
                        this.getData()
                    }
                }

            },

            sort(e) {
                console.log(e)

                let sort = this.$toSnakeCase(e.sortBy)
                // let sort = e.sortBy
                let sorting = e.sortDesc ? 'DESC' : 'ASC'

                this.$router.push({
                    query: {
                        ...this.$route.query,
                        orderBy: sort,
                        sorting: sorting
                    }
                })

                this.getData(1, this.perPage)

            },

            async getData(page = 1, perPage = this.perPage) {
                this.state.loading = true

                const queryParams = {
                    size: perPage,
                    page: page,
                    ...this.$route.query
                }
                const res = await this.$axios('/wallets/gather-requests', {params: queryParams})

                this.state.loading = false
                this.items = res.data.content
                this.currentPage = res.data.number + 1
                this.rows = res.data.totalElements
            },
        },
        created() {
            this.getData()
        }
    }
</script>
<style lang="scss">
    .vs__selected {
        width: calc(100% - 20px);
    }

    [v-cloak] {
        opacity: 0;
    }

    .nowrap {
        white-space: nowrap;
    }

    [dir="rtl"] .test-badge {
        font-size: 10px;
        top: -6px;
        left: -5px !important;
        min-width: 16px;
        min-height: 16px;
    }
</style>
